import { X } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';

export const ModalCloseButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation('common');
  return (
    <Dialog.Close asChild>
      <button
        type="button"
        className={className}
        data-testid="modal-close-button"
        aria-label={t('Close')}
      >
        <X size={DEFAULT_ICON_SIZES.lg} />
      </button>
    </Dialog.Close>
  );
};
