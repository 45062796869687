import { Moped } from '@phosphor-icons/react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { useRewards } from '@/core/hooks/useRewards';
import { selectCartProgressIndicatorVisibleRewardDescription } from '@/store/selectors/cartProgressIndicatorSelectors';
import { showRewardsDescription } from '@/store/slices/cartProgressIndicatorSlice';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

import { GiftIcon } from '../GiftIcon/GiftIcon';
import { Modal } from '../Modal/Modal';

const DeliveryRewardDescription = dynamic(
  async () =>
    (await import('./components/DeliveryRewardDescription'))
      .DeliveryRewardDescription,
  { ssr: false }
);

const GiftRewardDescription = dynamic(
  async () =>
    (await import('./components/GiftRewardDescription')).GiftRewardDescription,
  { ssr: false }
);

export const RewardDescriptionModal = () => {
  const { t } = useTranslation('common');

  const visibleRewardDescription = useAppSelector(
    selectCartProgressIndicatorVisibleRewardDescription
  );
  const dispatch = useAppDispatch();
  const isDeliveryModal = visibleRewardDescription === 'delivery';

  const handleOnClose = () => {
    dispatch(showRewardsDescription(null));
  };

  const actionButton = { onClick: handleOnClose, title: t('Ok, got it') };

  const title = isDeliveryModal ? <DeliveryRewardTitle /> : <GiftRewardTitle />;

  return (
    <Modal
      isOpen={visibleRewardDescription !== null}
      title={title}
      onOpenChange={handleOnClose}
      hideHeaderCloseButton
      titleClassName="font-semibold"
      actionButton={actionButton}
      classNames="lg:max-w-[455px]"
    >
      <div className="px-5 py-6 text-sm lg:px-0 lg:py-8">
        {isDeliveryModal ? (
          <DeliveryRewardDescription />
        ) : (
          <GiftRewardDescription />
        )}
      </div>
    </Modal>
  );
};

const DeliveryRewardTitle = () => {
  const { t } = useTranslation('common');
  return (
    <div className="flex items-center gap-3">
      <Moped weight="fill" size={DEFAULT_ICON_SIZES.lg} />{' '}
      {t('Get free delivery!')}
    </div>
  );
};

const GiftRewardTitle = () => {
  const { t } = useTranslation('common');
  const { giftReward } = useRewards();

  return (
    <div className="flex items-center gap-3">
      <GiftIcon
        rewardIconUrl={giftReward?.rewardIconUrl}
        size={DEFAULT_ICON_SIZES.lg}
      />
      {t('Get a discount!')}
    </div>
  );
};
