import { CaretLeft } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';

import { Button } from '../Button/Button';

import { ModalCloseButton } from './components/ModalCloseButton/ModalCloseButton';

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  title: ReactNode;
  classNames?: string;
  onPrev?: () => void;
  hideHeaderCloseButton?: boolean;
  titleClassName?: string;
  actionButton?: {
    title: string;
    onClick?: () => void;
    form?: string;
    isLoading?: boolean;
  };
  closeButton?: { title: string; onClick: () => void; disabled?: boolean };
};

export const Modal = ({
  classNames,
  onPrev,
  isOpen,
  onOpenChange,
  title,
  children,
  hideHeaderCloseButton,
  titleClassName,
  actionButton,
  closeButton,
}: ModalProps) => {
  const { t } = useTranslation('common');
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-over-chatbot flex bg-custom-modalBackdrop">
          <Dialog.Content
            aria-describedby={undefined}
            className={cn(
              'z-50 m-auto flex size-full justify-center lg:h-auto lg:w-[768px] lg:overflow-y-hidden lg:rounded-2xl',
              classNames
            )}
          >
            <div className="relative flex flex-1 flex-col overflow-y-auto bg-white lg:rounded-2xl lg:p-10">
              <div
                className={cn(
                  'flex min-h-[65px] items-center border-b border-border-subtle px-5 lg:block lg:min-h-fit lg:border-none lg:p-0',
                  onPrev && 'pl-0 pr-14 lg:p-0'
                )}
              >
                {onPrev && (
                  <button
                    type="button"
                    onClick={onPrev}
                    className="flex h-full w-14 items-center justify-center lg:hidden"
                    aria-label={t('Previous page')}
                  >
                    <CaretLeft size={DEFAULT_ICON_SIZES.lg} />
                  </button>
                )}
                <Dialog.Title
                  className={cn(
                    'mx-auto truncate text-center font-semibold lg:p-0 lg:text-left lg:text-title-lg lg:font-medium',
                    titleClassName
                  )}
                >
                  {title}
                </Dialog.Title>
                {!hideHeaderCloseButton && (
                  <ModalCloseButton className="absolute right-[18px] lg:hidden" />
                )}
              </div>
              <div className="flex flex-1 flex-col"> {children}</div>
              <ModalCloseButton className="absolute right-4 top-4 hidden lg:block" />
              {(actionButton || closeButton) && (
                <div className="sticky bottom-0 w-full space-y-3 border-t border-border-subtle bg-white px-5 py-6 lg:border-none lg:p-0">
                  {actionButton && (
                    <Button
                      onClick={actionButton.onClick}
                      className="w-full"
                      size="big"
                      form={actionButton.form}
                      type={actionButton.form ? 'submit' : 'button'}
                      isLoading={actionButton.isLoading}
                    >
                      {actionButton.title}
                    </Button>
                  )}
                  {closeButton && (
                    <Button
                      onClick={closeButton.onClick}
                      className="w-full lg:hidden"
                      size="big"
                      buttonStyle="outlined"
                      disabled={closeButton.disabled}
                    >
                      {closeButton.title}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
