globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"6.109.1"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

import { logger } from '@/config/logger';
import { publicEnv } from '@lib/env/public-env-vars';

(() => {
  if (
    !publicEnv.NEXT_PUBLIC_SENTRY_CLIENT_DSN &&
    publicEnv.NEXT_PUBLIC_ENVIRONMENT === 'prod'
  ) {
    logger.warn(
      "Missing NEXT_PUBLIC_SENTRY_CLIENT_DSN. Sentry won't be initialized."
    );
    return;
  }
  Sentry.init({
    dsn: publicEnv.NEXT_PUBLIC_SENTRY_CLIENT_DSN,
    tracesSampleRate: 0,
    maxValueLength: 1000,
    environment: publicEnv.NEXT_PUBLIC_ENVIRONMENT,
    beforeSendTransaction: (event) => {
      if (event.transaction === '/healthz') {
        // Filter out /healthz transaction, since it has no meaning in the sentry
        // dashboard. It's required for other monitoring services, though.
        return null;
      }
      return event;
    },
    ignoreErrors: [
      // This kind of error means that ResizeObserver was not able to deliver
      // all observations within a single animation frame. We can ignore this in
      // Sentry. The issue is probably related to poor performance of the scheduler
      // popup, but we're going to refactor it in the near future, so these
      // errors might go away then.
      // Sometimes it's also related to the old browser that we don't support.
      /.*ResizeObserver.*/gm,
      'ResizeObserver loop',
      'CART_NOT_FOUND',
      /Network Error/i,
      /Fetch Error/i,
    ],
    debug: false,
  });
})();
