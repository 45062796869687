import { selectCartItemTotal } from '../selectors/selectCartItemTotal';
import { hasReachedLevel } from '../services/hasReachedLevel';
import {
  findReward,
  isGiftReward,
  isShippingDiscountReward,
} from '../ui/components/CartProgressIndicator/helpers/findReward';

import { useCurrentCart } from './useCurrentCart';

export const useRewards = () => {
  const { data, loading } = useCurrentCart();
  const cart = data?.currentCart;

  const rewards = cart?.rewards;
  const deliveryReward = findReward(rewards, isShippingDiscountReward);
  const giftReward = findReward(rewards, isGiftReward);

  const lineItemsTotalPrice = cart ? selectCartItemTotal(cart.lineItems) : 0;

  const discountableLineItemsTotalCentAmount = cart
    ? selectCartItemTotal(
        cart.lineItems.filter((el) => el.product.attributes.discountable)
      )
    : 0;

  const hasFreeDelivery = hasReachedLevel(
    lineItemsTotalPrice,
    deliveryReward?.minimumCartValue?.centAmount
  );

  const hasGift = hasReachedLevel(
    discountableLineItemsTotalCentAmount,
    giftReward?.minimumCartValue?.centAmount
  );

  return {
    deliveryReward,
    giftReward,
    hasFreeDelivery,
    hasGift,
    loading,
  };
};
