import { Gift } from '@phosphor-icons/react';
import Image from 'next/image';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';

type IconSizesKey = keyof typeof DEFAULT_ICON_SIZES;

export const GiftIcon = ({
  rewardIconUrl,
  size = DEFAULT_ICON_SIZES.sm,
}: {
  rewardIconUrl: string | undefined;
  size?: (typeof DEFAULT_ICON_SIZES)[IconSizesKey];
}) =>
  rewardIconUrl ? (
    <Image
      src={rewardIconUrl}
      alt="Gift icon"
      width={size}
      height={size}
      className="max-w-min"
    />
  ) : (
    <Gift size={size} />
  );
